import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import ReportTable from './reportTable'

function ReportIndex() {
    return (
        <>
            <Navbar />
            <ReportTable />
            <FooterSection />
        </>
    )
}

export default ReportIndex
