import React from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import Queries from './queries'




function Myqueries() {
    return (
        <>
            <Navbar />
            <Queries />
            <FooterSection />

        </>
    )
}

export default Myqueries
