import React from 'react'
import Navbar from '../../navbar'
import Terms from './Terms'
import FooterSection from '..'

function TermsIndex() {
    return (
        <>
            <Navbar />
            <Terms />
            <FooterSection />
        </>
    )
}

export default TermsIndex
