import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import Combo from './combo'

function MenuIndex() {












    return (
        <>
            <Navbar />
            <Combo />
            <FooterSection />
        </>
    )
}

export default MenuIndex
