import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import News from './News'

function NewsTikerIndex() {
    return (
        <>
            <Navbar />
            <News />
            <FooterSection />
        </>
    )
}

export default NewsTikerIndex
