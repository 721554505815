import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Card, Input } from 'reactstrap';
import { productSearch } from '../../ApiConfigs/ApiConfig';
import axios from 'axios';
import './index.scss'
import { useNavigate } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import logo from '../../../src/assets/images/logo/logo.png'



function ProductModal({ isOpen, toggle, onClickProduct, closeSearchModal }) {


    const [productSearchData, setProductSearch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('')



    const productGet = async (searchKey) => {
        try {
            setLoading(true);
            const response = await axios.get(`${productSearch}?search=${searchKey}`);
            setProductSearch(response?.data?.result);
        } catch (error) {
            console.error('Error fetching product search:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        productGet(searchKey)

    }, [searchKey])

    const navigate = useNavigate()


    const navToProductView = (product_url) => {
        if (onClickProduct) {
            onClickProduct(product_url);
        } else {
            navigate(`/products/viewProduct/${product_url}`);
            toggle()
        }
    };



    return (
        <>
            <Modal className='searchModal' isOpen={isOpen} >

                <ModalHeader toggle={toggle || closeSearchModal} className="modern-modal-header">
                    <div className="d-flex">
                        <img src={logo} alt="logo" height={60} className="me-2" />
                        <h1 className="auth-head mt-2">Search Items</h1>

                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='nav-top-data' style={{ width: '100px', marginLeft: '37%' }}>
                        <form className="search-container1"
                        >
                            <input
                                type="text"
                                id="search-bar"
                                placeholder="Business Cards, Posters, Booklets..."
                                onChange={(e) => setSearchKey(e.target.value)}

                            />
                            <a href="#"><img className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                        </form>
                    </div>


                    <div style={{ marginTop: "-70px" }}>
                        <div className="search-results">
                            {productSearchData.map((productData) => (
                                <Card key={productData._id} style={{ cursor: "pointer" }} className='mt-2' onClick={() => navToProductView(productData?.product_url)}>
                                    <div className='d-flex' style={{ width: "100%" }} >
                                        <div className=''>
                                            <img src={productData?.image} style={{ height: "100%" }} width={100} className='img-fluid' alt='img' />
                                        </div>
                                        <div className='d-flex justify-content-between p-2' style={{ width: "100%" }} >
                                            <div>
                                                <p className='fw-bold'> {productData?.name}</p>
                                            </div>
                                            <div className="linkTag" >
                                                <p><ExternalLink /></p>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>


                </ModalBody>
            </Modal>

        </>
    );
}


export default ProductModal
