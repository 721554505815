import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import domtoimage from 'dom-to-image';
import { AiOutlineSync } from "react-icons/ai";
import { FaQuestionCircle } from "react-icons/fa";
import './index.css';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { product, addCart, cart, fileUpload } from '../../../ApiConfigs/ApiConfig'
import Preview from './preview';
import toast from 'react-hot-toast';

function ProductCustomize() {
  const { id } = useParams(); 
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [color, setColor] = useState("");
  const [customPicture, setCustomPicture] = useState(null);
  const [imageOrder, setImageOrder] = useState([]);
  const [mouseTimeout, setMouseTimeout] = useState(null);
  const canvasRef = useRef(null);
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [showDelBtn, setShowDeBtn] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  // const [editableIndex, setEditableIndex] = useState(0);
  const [loader, setloader] = useState(false);
  const [textOptions, setTextOptions] = useState({
    text: '',
    fontSize: '',
    fontFamily: '',
    textColor: '#000000'
  });

  const CardImg = [
    ...(selectedProduct?.customizeImages ?? [])
  ]
  const isButtonDisabled = imageOrder.length < CardImg.length;
  const [mainImage, setMainImage] = useState("");

  const [payloadData,setPayloadData] = useState({});

  const isLogin = localStorage.getItem('role')

  const quantity = localStorage.getItem('quantity');

  const field = JSON.parse(localStorage.getItem('selectedOptions'));
  let canvas;

  useEffect(() => {
  const additionalData = async () => {
    try {
      const response = await axios.get(`${product}/${id}`);
      const productData = response?.data?.result;
      setSelectedProduct(productData);

      // Set the 0th index image as the default image
      if (productData?.customizeImages && productData.customizeImages.length > 0) {
        setMainImage(productData.customizeImages[0]);
        setSelectedImageIndex(0);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };
  additionalData();
}, [id]);

  const resetForm = () => {
    setSelectedImageUrl("");
    setColor("");
    setCustomPicture(null);
    setTextOptions({
      text: "",
      fontSize: '',
      fontFamily: "",
      textColor: "#000000"
    });

    // Clear the fabric.js canvas
    if (fabricCanvas) {
      fabricCanvas.clear();
    }

    // Reset file input value
    const fileInput = document.getElementById("tshirt-custompicture");
    if (fileInput) {
      fileInput.value = "";
    }

    // Reset color input
    const colorInput = document.getElementById("tshirt-color");
    if (colorInput) {
      colorInput.value = "#fff";
    }

    // Reset background color
    const tshirtDiv = document.getElementById("tshirt-div");
    if (tshirtDiv) {
      tshirtDiv.style.backgroundColor = "#fff";
    }
  };

  const reset = () => {
    setSelectedImageUrl("");
    setColor("");
    setCustomPicture(null);
    setTextOptions({
      text: "",
      fontSize: '',
      fontFamily: "",
      textColor: "#000000"
    })};

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const newFabricCanvas = new fabric.Canvas(canvas);
    setFabricCanvas(newFabricCanvas);

    // Add event listener for delete key
    document.addEventListener("keydown", handleKeyDown);

    // Listen for object selection
    newFabricCanvas.on("selection:created", () => {
      console.log("object selected", newFabricCanvas);
      setShowDeBtn(true)
      // setFabricCanvas(newFabricCanvas);
    });

    // Listen for deselection
    newFabricCanvas.on("selection:cleared", () => {
      setShowDeBtn(false)
      // setFabricCanvas(null);
    });

    return () => {
      newFabricCanvas.dispose();
      // Cleanup event listener
      document.removeEventListener("keydown", handleKeyDown);
      newFabricCanvas.off("selection:created"); // Remove selection event listener
      newFabricCanvas.off("selection:cleared"); // Remove deselection event listener
    };
  }, []);

  useEffect(() => {
    if (fabricCanvas && selectedImageUrl) {
      updateTshirtImage(selectedImageUrl);
    }
  }, [fabricCanvas, selectedImageUrl]);

  const updateTshirtImage = (imageURL) => {
    fabric.Image.fromURL(imageURL, function (img) {
      img.scaleToHeight(300);
      img.scaleToWidth(300);
      //   fabricCanvas.clear();
      fabricCanvas.add(img).renderAll();
    }, { crossOrigin: 'anonymous' });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;

      imgObj.onload = function () {
        if (!fabricCanvas) return;

        const img = new fabric.Image(imgObj);

        img.scaleToHeight(300);
        img.scaleToWidth(300);
        // fabricCanvas.clear();
        fabricCanvas.add(img).renderAll();
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDesignChange = (e) => {
    setSelectedImageUrl(e.target.value);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
    document.getElementById("tshirt-div").style.backgroundColor = e.target.value;
  };

  const downloadImage = () => {

    // Clear selection
    if (fabricCanvas) {
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }

    // Set canvas border to transparent and width to zero
    const canvasElement = document.getElementById('drawingArea');
    if (canvasElement) {
      canvasElement.style.border = 'none';
    }


    domtoimage.toBlob(document.getElementById('tshirt-div'))
      .then(function (blob) {
        var link = document.createElement('a');
        link.download = 'custom-tshirt.png';
        link.href = window.URL.createObjectURL(blob);
        link.click();
        window.URL.revokeObjectURL(link.href);
        canvasElement.style.border = '1px solid #ccc';
      });
  };

  const deleteImage = () => {
    if (fabricCanvas && fabricCanvas.getActiveObject()) {
      fabricCanvas.remove(fabricCanvas.getActiveObject());
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }
  }
  const handleKeyDown = (event) => {
    console.log("Key pressed:", event.key);
    // Check if the delete key is pressed
    if (event.key === "Delete" || event.key === "Backspace") {
      // Check if a fabric object is selected
      console.log("fabricCanvas", fabricCanvas);
      console.log("fabricCanvas.getActiveObject()", fabricCanvas ? fabricCanvas.getActiveObject() : '');
      if (fabricCanvas && fabricCanvas.getActiveObject()) {
        fabricCanvas.remove(fabricCanvas.getActiveObject());
        fabricCanvas.discardActiveObject();
        fabricCanvas.renderAll();
      }

    }
  };

  const handleTextChange = (e) => {
    setTextOptions({
      ...textOptions,
      text: e.target.value
    });
  };

  const handleFontSizeChange = (e) => {
    setTextOptions({
      ...textOptions,
      fontSize: parseInt(e.target.value)
    });
  };

  const handleFontFamilyChange = (e) => {
    setTextOptions({
      ...textOptions,
      fontFamily: e.target.value
    });
  };

  const handleTextColorChange = (e) => {
    setTextOptions({
      ...textOptions,
      textColor: e.target.value
    });
  };

  const addText = () => {
    if (!fabricCanvas) return;

    const text = new fabric.Text(textOptions.text, {
      left: 50,
      top: 50,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor
    });

    fabricCanvas.add(text);
  };

  const handleSaveAndProceed = async () => {
    
    const payload = {
      product: selectedProduct?._id,
      quantity: quantity,
      field: field,
      customizeImages: imageOrder.map(image => image.url)
    };

    console.log('Payload:', payload);

    setPayloadData(payload)

    
    if (isLogin === 'USER') {
      try {
        const response = await axios.post(addCart, payload);
        toast.success(response?.data?.msg)
        localStorage.removeItem('AddCart')
        navigate('/myCart');
      } catch (error) {
        toast.error(error?.response?.data?.msg)

      }
    } else {
        // toggle();
        alert('You are not signed as a User');

    }
    
  }

  // const handleImageClickWrapper = (image, index) => {
  //   if (index <= editableIndex) {
  //     setSelectedImageIndex(index);      
  //     setMainImage(image);
  //     // Clear the canvas before displaying the clicked image
  //     // if (fabricCanvas) {
  //     //   fabricCanvas.clear();
  //     // }
  //   }
  // };

  const handleImageClickWrapper = (image, index) => {
    // if (index <= editableIndex) {
      console.log(`Image clicked: ${index}`);
      setMainImage(image);
      setSelectedImageIndex(index);
      // setEditableIndex(index);
       // Update the mainImage state to display the selected image
    // }
  };

  // useEffect(() => {
  //   setMainImage(CardImg[editableIndex]);
  //   console.log('Editable index changed:', editableIndex);
  // }, [editableIndex, CardImg]);

  const handleSaveClick = async (image, index) => {
    if (fabricCanvas) {
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }

    // Set canvas border to transparent and width to zero
    const canvasElement = document.getElementById('drawingArea');
    if (canvasElement) {
      canvasElement.style.border = 'none';
    }
    try {
      setloader(true);
      // Get the HTML element for the T-shirt design
      const element = document.getElementById('tshirt-div');
  
      // Convert the DOM element to a Blob
      const blob = await domtoimage.toBlob(element);
      const randomIndex = getRandomNumberNearIndex(index);
  
      const formData = new FormData();
      formData.append('file', blob, `design${index}_${randomIndex}.png`);
      const response = await axios.post(fileUpload, formData);
      
      if (response.status === 200 && response.data.result && response.data.result.length > 0) {
        const url = { url: response.data.result[0].location };
        // Update profileImages state with the new image URL
        setImageOrder((prevOrder) => {
          const updatedOrder = [...prevOrder];
          updatedOrder[index] = url;
          console.log(updatedOrder);
          return updatedOrder;
        });          
      } else {
          // Show error message if no image URL found in response
          alert('Error saving image: No result found in response.');
      }     
      console.log(imageOrder);
      console.log('Image saved successfully!');

      // Clear the canvas
      resetForm();
      // toggle();
      // alert('If you want to ');
      if (selectedImageIndex < CardImg.length - 1) {
        setSelectedImageIndex((prevIndex) => prevIndex + 1);
        setMainImage(CardImg[selectedImageIndex + 1]);
      }
      
      // Enable the next image for editing
      // if (editableIndex < CardImg.length - 1) {
      //   setEditableIndex((prevIndex) => prevIndex + 1);
      //   // Set the next image as the active image to edit
      //   setSelectedImageIndex((prevIndex) => prevIndex + 1);
      //   // Update mainImage to the next image
      //   setMainImage(CardImg[editableIndex + 1]);
      // }

    } catch (error) {
      if (error && error.message) {
        alert(`Error capturing image: ${error.message}`);
      } else {
        alert('An error occurred while capturing the image.');
      }
    } finally {
      // Hide loader after image saving attempt
      setloader(false);
    }
  };

  // const handleImageLoad = (event) => {
  //   const { naturalWidth, naturalHeight } = event.target;
  //   setImageDimensions({ width: naturalWidth, height: naturalHeight });
  // };

  const getRandomNumberNearIndex = (index) => {
    const min = 0;
    const max = 9;
    return index + Math.floor(Math.random() * (max - min + 1) + min);
  };

  const canvasElement = document.getElementById('drawingArea');
  if (canvasElement) {
    canvasElement.style.border = 'none';
  }

  // const handleViewImage = (index) => {
  //   // Ensure the index is within bounds
  //   if (index >= 0 && index < imageOrder.length) {
  //     // Set mainImage to the edited image URL in imageOrder
  //     setMainImage(imageOrder[index].url);
  //     setSelectedImageIndex(index);
  //     // setEditableIndex(index);
  //   }
  // };
  
  // // Function to handle mouse movement
  // const handleMouseMove = () => {
  //   if (mouseTimeout) {
  //     clearTimeout(mouseTimeout);
  //   }
  //   setMouseTimeout(setTimeout(() => handleSaveClick(currentImage, currentIndex), 1000));
  // };

    // useEffect(() => {
    //   const handleMouseMove = () => {
    //     if (mouseTimeout) {
    //       clearTimeout(mouseTimeout);
    //     }
    //     setMouseTimeout(setTimeout(() => handleSaveClick(mainImage, selectedImageIndex), 5000));
    //   };
    
    //   window.addEventListener('mousemove', handleMouseMove);
    
    //   return () => {
    //     window.removeEventListener('mousemove', handleMouseMove);
    //   };
    // }, [mouseTimeout, mainImage, selectedImageIndex]);

      const handleNavigateBack = () => {
    if (imageOrder.length > 0) {
      const confirmMessage = 'If you go back, the saved images will be deleted.';
      const shouldNavigate = window.confirm(confirmMessage);
      if (shouldNavigate) {
        // Perform navigation
        navigate(-1);
      }
    } else {
      const confirmMessage = 'Are you sure you want to navigate back?';
      const shouldNavigate = window.confirm(confirmMessage);
      if (shouldNavigate) {
        // Perform navigation
        navigate(-1);
      }
    }
    // Optionally, handle other logic based on user's choice
  };

  return (
    <div>
      {/* Customize Product Header  */}
      <div className='customize-header'>
        <div className='d-flex align-items-center'>
        <h1 style={{cursor:'pointer'}} className='pb-3 m-0 me-3' onClick={handleNavigateBack}><ArrowLeft /></h1>
          <img src='https://samsel.s3.amazonaws.com/printonlogo.png' height={45} alt="logo" />
          <h3 className='heading-product'>{selectedProduct?.name}</h3>
        </div>
        <div className='d-flex align-items-center mt-2 mt-md-0'>
          <div className='customize-question'>
            <span><FaQuestionCircle /></span>
          </div>
          <div className='customize-reset' onClick={resetForm}>
            <span><AiOutlineSync /></span>
          </div>
          <button className='btn-save-customize' onClick={toggle}>
            Preview
          </button>
          {isButtonDisabled ? (
            null
          ) : (
            <button className='ms-1 btn-save-customize' onClick={handleSaveAndProceed}>
              Save design & Proceed <ArrowRight />
            </button>
          )}
        </div>
      </div>

      {/* Customize Product Content */}
      <div className='customize-product-container'>
        <div className="vertical-images col-lg-2 mt-2">
          {CardImg.map((image, index) => (
            <div key={index} className='image-container ms-lg-4' style={{ width: '200px', height: 'auto' }}>
              <img
                className=''
                src={image}
                alt={""}
                style={{ width: '200px', height: 'auto', cursor: 'pointer',border: selectedImageIndex === index ? '1px solid #e44324' : 'none'  }}
                onClick={() => handleImageClickWrapper(image, index)  }
              />
              {selectedImageIndex === index && (
                <div style={{alignItems:'center', display:'flex', justifyContent:'center'}}>
                  <button 
                    className='btn-save mb-4 ms-2' 
                    style={{ marginTop: '10px', color:'white', background:'#37a003', border:'none', borderRadius:'5px',  }} 
                    onClick={() => handleSaveClick(image,index)}
                    >
                    Save
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='left-container-img col-lg-7 pl-sm-5'>
          <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ position: 'relative', overflow: 'auto', width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '80vh' }}>
              <div style={{ width: `500px`, height: `500px`, position: 'relative' }} id="tshirt-div">
                <img
                  style={{ width: `500px`, height: `500px` }}
                  id="tshirt-backgroundpicture"
                  src={mainImage}
                  alt="T-shirt"
                  // onLoad={handleImageLoad}
                />
                <div
                  id="drawingArea"
                  style={{ position: 'absolute', top: 0, left: 0, width: `500px`, height: `500px` }}
                  className="drawing-area"
                >
                  <div style={{ width: '500px', height: '500px' }} className="canvas-container">
                    <canvas ref={canvasRef} id="tshirt-canvas" width= '500px' height= '500px' style={{ display: 'block' }} ></canvas>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <p className='mt-3'>To remove a loaded picture on the T-Shirt select it and press the <kbd>DEL</kbd> Button.</p>
              {showDelBtn && (
                <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
              )}
            </div>
          </div>
        </div>

        <div className='right-container-edit'>
          <div className='mb-3'>
            <label htmlFor="tshirt-design" className='select-customize-label'>Design:</label>
            <select id="tshirt-design" className='customize-select' onChange={handleDesignChange} value={selectedImageUrl}>
              <option value="">Select one of our designs ...</option>
              <option value="http://localhost:3000/avatar.png">Batman</option>
            </select>
          </div>
          <div className='mb-2'>
            <label htmlFor="tshirt-color" className='select-customize-label mt-1 mb-1'> Color:</label>
            <select id="tshirt-color" className='customize-select mt-1 mb-1' onChange={handleColorChange} value={color}>
              <option value="">--Select a color--</option>
              <option value="#fff">White</option>
              <option value="#000">Black</option>
              <option value="#f00">Red</option>
              <option value="#008000">Green</option>
              <option value="#ff0">Yellow</option>
            </select>
          </div>
          <div className='mb-3'>
            <label htmlFor="tshirt-custompicture" className='select-customize-label'>Upload your own design:</label>
            <input type="file" id="tshirt-custompicture" onChange={handleFileUpload} />
          </div>
          <div className='mb-3'>
            <label htmlFor="text" className='select-customize-label'>Text:</label>
            <input type="text" id="text" className='customize-select' placeholder='Enter Text Here' value={textOptions.text} onChange={handleTextChange} />
          </div>
          <div className='mb-3'>
            <label htmlFor="fontSize" className='select-customize-label'>Font Size:</label>
            <input type="number" id="fontSize" className='customize-select' placeholder='Enter Font Size ' value={textOptions.fontSize} onChange={handleFontSizeChange} />
          </div>
          <div className='mb-2'>
            <label htmlFor="fontFamily" className='select-customize-label mt-1 mb-1'>Font Family:</label>
            <select id="fontFamily" className='customize-select mt-1 mb-1' value={textOptions.fontFamily} onChange={handleFontFamilyChange}>
              <option value="">--Select An Option--</option>
              <option value="Arial">Arial</option>
              <option value="Verdana">Verdana</option>
              <option value="Tahoma">Tahoma</option>
            </select>
          </div>
          <div className='mb-3'>
            <label htmlFor="textColor" className='select-customize-label'>Text Color:</label>
            <input type="color" id="textColor" className='customize-select' value={textOptions.textColor} onChange={handleTextColorChange} />
          </div>
          <div className='mb-3'>
            <button className='btn btn-secondary' onClick={() => {addText(); reset();}}>Add Text</button>
            <button id="downloadBtn" className='btn btn-primary ms-1' onClick={downloadImage}>Download Image</button>
          </div>
        </div>
      </div>
      <Preview modal={modal} toggle={toggle} cardImages={imageOrder} />
    </div>
  );
}

export default ProductCustomize;
