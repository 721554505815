import React, { useEffect, useState } from 'react';
import { productApi, productCategory, productStatus, productTable } from '../../../ApiConfigs/ApiConfig';
import DataTable from 'react-data-table-component';
import { Edit2, Eye, Plus, Trash } from 'react-feather';
import axios from 'axios';
import './index.css';
import { toast } from 'react-hot-toast';
import { Button, FormFeedback, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router';
import Navbar from '../../navbar';
import FooterSection from '../../footer';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { GrPowerReset } from "react-icons/gr";

function ProductIndex() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [getData, setGetData] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [enteredProductName, setEnteredProductName] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const toggleEdit = (id) => {
        navigate(`/admin/editProduct/${id}`);
    };
    const openProductType = (id) => {
        navigate(`/admin/productType/${id}`);
    };


    const productSchema = yup.object().shape({
        productCategory: yup.string().required('Product category is required')
    });

    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(productSchema)
    });


    const getProduct = async () => {
        try {

            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }
    useEffect(() => {
        getProduct()
    }, [])

    const options = getData?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))




    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${productTable}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData(searchKey)
    }, [sorts, searchKey, currentPage, rowsPerPage])


    const updateStatus = async (id) => {
        try {
            const response = await axios.post(`${productStatus}/${id}`);
            additionalData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
        setEnteredProductName('');

    }




    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${productApi}/${deleteData?._id}?productName=${enteredProductName}`)
            closeDeleteModal()
            additionalData()
            setEnteredProductName('');
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.msg)
        }

    }
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };

    const handleReset = () => {
        setSorts('');
        additionalData();
        setResetKey((prevKey) => prevKey + 1);
    };

    const productSortData = [
        {
            name: "latest",
            value: "latest"
        },
        {
            name: "oldest",
            value: "oldest"
        }
    ]

    const additionalTable = [
        {
            name: 'S No.',
            maxWidth: '20px',
            cell: (row, i) => i + 1,

        },
        {
            name: 'Image',
            sortable: false,
            maxWidth: '250px',
            cell: row => <img src={row?.image} height={100} width={150} alt="" style={{ padding: '5px' }} />,
        },
        {
            name: 'Name',
            sortable: false,
            maxWidth: '250px',
            cell: row => row?.name,
        },
        {
            name: 'Description',
            sortable: false,
            maxWidth: '500px',
            cell: row => <span onClick={toggleDescription} className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
                {showFullDescription ? row?.fullDescription : truncateDescription(row?.fullDescription, 50)}</span>,
        },
        // {
        //     name: 'Description',
        //     sortable: false,
        //     maxWidth: '150px',
        //     cell: row => <span onClick={() => navigate(`/admin/product-description/${row?._id}`)} style={{ cursor: 'pointer' }}><Plus /></span>
        // },
        {
            name: 'Category',
            sortable: false,
            maxWidth: '250px',
            cell: row => row?.productCategory?.name,
        },

        {
            name: 'FAQ',
            sortable: false,
            maxWidth: '100px',
            cell: row => <span onClick={() => navigate(`/admin/product/faq/${row?._id}`)} style={{ cursor: 'pointer' }}><Plus /></span>
        },
        {
            name: 'Status',
            maxWidth: '100px',
            cell: (row) => (
                <>
                    <div class="switch">
                        <Input
                            type="switch"
                            id="switch-success"
                            name="success"
                            onChange={async (e) => {
                                const result = window.confirm(
                                    `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                                );
                                if (result) {
                                    await updateStatus(row._id);
                                } else {

                                    e.target.checked = !e.target.checked;
                                }
                            }}
                            defaultChecked={row.status === 'active'}
                        />
                    </div>

                </>
            )
        },
        {
            name: "Action",
            maxWidth: "200px",
            cell: (row) => (
                <>
                    <div className='Edit cursor-pointer me-2' style={{ cursor: 'pointer' }} onClick={() => toggleEdit(row?._id)}> <Edit2 size={20} /></div>
                    <div className='me-2' style={{ cursor: 'pointer' }} onClick={() => openProductType(row?._id)}><Eye /></div>
                    <div style={{ cursor: 'pointer' }} onClick={() => openDeleteModal(row)}><Trash size={20} color='red' /></div>
                </>
            )
        }
    ]
    const navToProduct = () => {
        navigate('/addProduct')
    }

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName="pagination justify-content-end p-1"
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    forcePage={currentPage - 1}
                />
            </>
        );
    };

    return (
        <>
            <Navbar />

            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div style={{ display: 'flex', marginBottom: '-30px' }}>
                    <h1>PRODUCTS</h1>
                    <div className='total-count'> {data?.pagination?.total}</div>
                    <div className="ms-4">
                        <Select
                            className="react-select me-2"
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 9999, }),
                            }}
                            key={`status${resetKey}`}
                            type='select'
                            options={productSortData?.map((item) => ({
                                label: item?.name,
                                value: item?.value
                            }))}
                            placeholder='Sort By'
                            onChange={(selectedOption) => setSorts(selectedOption.value)}
                        />
                    </div>
                    <div style={{ marginRight: "20px", marginTop: '10px', color: '#e4510b' }}>
                        <GrPowerReset size={25} onClick={handleReset} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                        <form className="search me-2">
                            <input
                                type="text"
                                id="search-bar"
                                placeholder="Search Name"
                                onChange={(e) => setSearchKey(e.target.value)}
                                style={{ color: 'black', borderColor: 'black', paddingRight: '50px' }}
                            />
                            <a href="#"><img className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                        </form>
                        <button className='productCategoryBtn' onClick={navToProduct} >Add Product</button>
                    </div>
                </div>
                {loading ? (
                    <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            color="primary"
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <div>
                        <DataTable
                            pagination
                            paginationServer
                            highlightOnHover
                            fixedHeader
                            fixedHeaderScrollHeight='130vh'
                            data={data?.rows || []}
                            columns={additionalTable}
                            paginationDefaultPage={currentPage}
                            paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                        />
                    </div>
                )}
            </div>
            <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>
                    Are you sure you want to delete this Product<br />"<label>{deleteData?.name}</label>" ?
                    <p className='mt-4' style={{ fontSize: '17px' }}>Enter Your Product Name<span style={{ color: 'red' }}>*</span></p>
                    <input
                        type='text'
                        className='delete-modal-input mb-4'
                        placeholder=' Product Name'
                        value={enteredProductName}
                        onChange={(e) => setEnteredProductName(e.target.value)}
                        style={{ borderRadius: '8px' }}
                    />                </ModalBody>
                <div style={{ justifyContent: 'center' }} className="modal-footer">
                    <Button style={{ backgroundColor: "#E4510B", border: 'none' }} disabled={deleteData?.name !== enteredProductName} onClick={handleConfirmDelete} >
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={closeDeleteModal} >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <FooterSection />
        </>
    )
}

export default ProductIndex
