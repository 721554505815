// CommonLayout.js
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { domainUrl } from "../../ApiConfigs/ApiConfig";

const CommonLayout = () => {
  const location = useLocation();

  // Construct the canonical URL based on current pathname
  const canonicalUrl = `${domainUrl}${location.pathname}`;
  console.log("canonicalUrl", canonicalUrl);
  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CommonLayout;
