import React, { useState } from 'react'
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const Preview = ({ modal, toggle, cardImages }) => {

    console.log(cardImages);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}> Preview</ModalHeader>
                <Form>
                    <ModalBody>
                        {cardImages.length === 0 ? (
                            <div style={{ textAlign: 'center', margin: '20px' }}>
                                <h5>Your product customization hasn't been edited yet. Please make changes before viewing the preview.</h5>
                            </div>
                        ) : (
                            cardImages.map((image) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }} key={image.url}>
                                    <img
                                        className='mb-3'
                                        style={{ width: '300px', height: "300px" }}
                                        id="tshirt-backgroundpicture"
                                        alt=""
                                        src={image.url}
                                    />
                                </div>
                            ))
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}

export default Preview