import React from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import Wishlist from './wishlist'




function MyWishListIndex() {
    return (
        <>
            <Navbar />
            <Wishlist />
            <FooterSection />
        </>
    )
}

export default MyWishListIndex
